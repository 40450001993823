@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600&display=swap");

.Whole_div {
  background-repeat: repeat;
  background-size: auto 100vh;
  font-family: "Montserrat", sans-serif;
  position: relative;
}

.mediaInfo {
  margin-top: 10vh;
  background-size: auto 100vh;
}


.color_sectiom {
  background-color: #FF5E8F;
  padding-bottom: 5rem;
}

.backtotop {
  position: fixed;
  display: flex;
  justify-content: center;

  bottom: 10px;
  float: right;
  right: 18.5%;
  left: 95.25%;
  width: 50px;
  height: 50px;
  font-size: 12px;
  /* border: 5px; */
  /* border-style: solid; */
  /* border-width: 1px; */
  /* border-color: #121930; */
  background-color: #d2e2f7;
  /* padding: .px; */
  border-radius: 25%;
  cursor: pointer;
}

.backtotop p {
  display: inline-block;
  padding-top: 4.7px;
  margin-top: 9px;
  margin-left: 17px;
  /* line-height: normal; */
  vertical-align: middle;
}


.backtotop a:hover {
  color: #000000;
}

@media (max-width: 1120px) {
  .backtotop {
    left: 80.25%;
  }
}

/* .backtotop:hover {
  background-color: #7dbbf1;
} */

h1 {
  text-align: center;
  font-size: 4rem;
}

.Row {
  text-align: center;
}

.info {
  margin-top: 20vh;
  color: white;
}

.links{
  /* cursor: pointer; */
}

/* .single_row{
display: flex;
align-content: center;
align-items: center;
} */

.d-image img {
  width: 100%;
  position: relative;
  transition: 0.3s;
  top: 0px;
  transition: top 0.2s linear;
}

.d-image img:hover {
  opacity: 0.9;
  top: 10px;
}

.logoSection {
  text-align: center;
  padding: 20vh 10vh 0 10vh;
  margin-top: 3vh;
  margin-bottom: 3vh;
}

.members {
  padding: 0vh 10vh 0 10vh;
}

.prizesection {
  text-align: center;
  /* margin: 0 auto; */
  /* padding: 0 4vh 0 4vh; */
  padding: 8vh 14vh 4vh 14vh;
  /* border-radius: 14px; */
  margin-top: 6vh;
  /* background-color:; */
}

.sponsorSection {
  padding: 17vh 18vh 0 18vh;
  margin-top: -10vh;
  margin-top: 0vh;
}

.faqsSection {
  text-align: center;
  margin: 0 auto;
  padding: 17vh 18vh 0 18vh;
  margin-top: -16vh;
}

.devfolio-img img {
  width: 17%;
  margin-right: 2%;
}

@media (max-width: 570px) {
  .mediaInfo {
    margin-top: 0;
  }

  .Whole_div {
    background-size: auto 100vh;
  }

  .faqsSection {
    padding: 0;
    margin-top: 0vh;
  }

  .members {
    margin: 0;
    padding: 0;
  }

  .prizesection {
    margin: 5vh 0 0 0;
    padding: 3vh 0 0 2vh;
  }

  .sponsorSection {
    padding: 0;
    margin: 0;
    margin: 12rem auto;
  }

  .logoSection {
    text-align: center;
    padding: 20vh 0vh 0 0vh;
  }
}

:root {
  --red: hsl(0, 78%, 62%);
  --cyan: hsl(180, 62%, 55%);
  --orange: hsl(34, 97%, 64%);
  --blue: hsl(212, 86%, 64%);
  --varyDarkBlue: hsl(234, 12%, 34%);
  --grayishBlue: hsl(229, 6%, 66%);
  --veryLightGray: hsl(0, 0%, 98%);
  --weight1: 200;
  --weight2: 400;
  --weight3: 600;
}

/* body {
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    background-color: var(--veryLightGray);
} */

.box p {
  color: var(--grayishBlue);
  font-size: 1.9rem;
}

.box {
  border-radius: 5px;
  box-shadow: 0px 30px 40px -20px var(--grayishBlue);
  padding: 15px;
  margin: 20px;
  /* background-color: rgb(255, 253, 248); */
}

.imgright {
  float: right;
  height: 50px;
}

.box {

  -webkit-animation: mover 1s infinite alternate;
  animation: mover 2s infinite alternate;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(10px);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(10px);
  }
}


@media (max-width: 450px) {
  .box {
    height: 180px;
    width: 90%;
  }
}

@media (max-width: 950px) and (min-width: 450px) {
  .box {
    text-align: center;
    height: 250px;
  }
}

.cyan {
  border-top: 3px solid var(--cyan);
}

.red {
  border-top: 3px solid var(--red);
}

.blue {
  border-top: 3px solid var(--blue);
}

.orange {
  border-top: 3px solid var(--orange);
}

.color1 {
  border-top: 3px solid #92278f;
}

.color2 {
  border-top: 3px solid #262262;
}

.cyan {
  border-bottom: 3px solid var(--cyan);
}

.red {
  border-bottom: 3px solid var(--red);
}

.blue {
  border-bottom: 3px solid var(--blue);
}

.orange {
  border-bottom: 3px solid var(--orange);
}

.color1 {
  border-bottom: 3px solid #92278f;
}

.color2 {
  border-bottom: 3px solid #262262;
}



h2 {
  /*     color: var(--varyDarkBlue);
    font-weight: var(--weight3); */
  font-size: 20px;
}


@media (min-width: 950px) {
  .row1-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-bottom: 20px; */
  }

  .margfromsponsor1 {
    margin-bottom: 30px;
  }

  .row2-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /*     .box-down {
        position: relative;
        top: 90px;
    } */

  /*     .box-down1 {
        position: relative;
        top: 150px;
    } */

  .box {
    width: 25%;

  }

}

@media (max-width: 890px) {
.backtotop p {
  margin-left: 2px;

}
}

/* track prize section */

body .containertrack {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1500px;
  margin: 35px 0;
}

body .containertrack .cardtrack {
  position: relative;
  min-width: 290px;
  height: 440px;
/*   box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
    inset -5px -5px 15px rgba(255, 255, 255, 0.1),
    5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1); */
  border-radius: 15px;
  margin: 5px;
  transition: 0.5s;
}

body .containertrack .cardtrack:nth-child(1) .boxtrack .contenttrack a {
  background: hsl(212, 86%, 64%);
}

body .containertrack .cardtrack:nth-child(2) .boxtrack .contenttrack a {
  background: hsl(34, 97%, 64%);
}

body .containertrack .cardtrack:nth-child(3) .boxtrack .contenttrack a {
  background: hsl(180, 62%, 55%);
}

body .containertrack .cardtrack:nth-child(4) .boxtrack .contenttrack a {
  background: hsl(0, 78%, 62%);
}

.imgtrack{
  width: 200px;
  height: 80px;
}

body .containertrack .cardtrack .boxtrack {
  position: absolute;
  top: 20px;
  left: 15px;
  right: 15px;
  bottom: 20px;
  background:  #f0efef;
  border-radius: 15px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  overflow: hidden;
  transition: 0.5s;
}

body .containertrack .cardtrack .boxtrack:hover {
  transform: translateY(-50px);
}

body .containertrack .cardtrack .boxtrack:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.03);
}

body .containertrack .cardtrack .boxtrack .contenttrack {
  padding: 20px;
  text-align: center;
}

body .containertrack .cardtrack .boxtrack .contenttrack h1 {
  font-size: 5rem;
  color: #fff;
}

body .containertrack .cardtrack .boxtrack .contenttrack h3 {
  font-size: 1.8rem;
  color: #fff;
  z-index: 1;
  transition: 0.5s;
  margin-bottom: 15px;
}

body .containertrack .cardtrack .boxtrack .contenttrack p {
  font-size: 1.2rem;
  font-weight: 300;
  /* color: rgba(255, 255, 255, 0.9); */
  color: black;
  z-index: 1;
  transition: 0.5s;
}

body .containertrack .cardtrack .boxtrack .contenttrack a {
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  background: black;
  border-radius: 5px;
  text-decoration: none;
  color: white;
  margin-top: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}
body .containertrack .cardtrack .boxtrack .contenttrack a:hover {
  /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6); */
  background: #fff;
  color: #000;
}

/* Modal */

.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}