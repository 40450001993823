@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@700&display=swap");
.h1 {
  margin: 0;
}
.dev-logo {
  fill: rgb(255, 255, 255);
}
.dev-button {
  align-items: center;
  background-color: #3770ff;
  border-radius: 4px;
  color: #fff;
  display: inline-flex;
  font-size: 20px;
  font-weight: 600;
  height: 60px;
  justify-content: center;
  outline: none;
  padding: 0 32px;
  transition: all 0.1s ease-in 0s;
  white-space: nowrap;
  width: 50%;

}
.dis-button {
  align-items: center;
  background-color: #7289da;
  border-radius: 4px;
  color: #fff;
  display: inline-flex;
  /* font-size: px; */
  font-weight: 600;
  height: 45px;
  top: -9px;
  justify-content: center;
  outline: none;
  padding: 0 0px;
  transition: all 0.1s ease-in 0s;
  white-space: nowrap;
  width: 10%;
}

/* .dis-button {
  align-items: center;
  background-color: #7289da;
  border-radius: 4px;
  color: #fff;
  display: inline-flex;
  font-size: px;
  font-weight: 600;
  height: 60px;
  justify-content: center;
  outline: none;
  padding: 0 20px;
  transition: all 0.1s ease-in 0s;
  white-space: nowrap;
  width: 10%;
} */
button {
  width: 22rem;
  height: 6rem;
  border: none;
  border-radius: 19px;
  font-size: 2rem;
  color: rgb(255, 255, 255);
  background-color: #8C52FF;
  font-weight: 300;
  position: relative;
}

.join_disco {
  width: 25rem;
  background: linear-gradient(90deg, #ae67fa -13.86%, #f49867 100%);
}

.join_disco i {
  margin-right: 10px;
}

.join_dis {
  margin-bottom: 29px;
  margin-top: 19px;
}

.linkclass{
  display: inline;
}

.register {
  margin-left: 9rem;
  border: 2px solid rgb(255, 255, 255);
  background-color: transparent;
}

.AboutMe h2 {
  font-size: 10rem;
  font-family: "Noto Sans", sans-serif;
}

p {
  font-size: 2rem;
  line-height: 1.6;
  font-weight: 300;
}

.shortjoin{
  font-size: 2rem;
}

.Myinfo {
  text-align: left;
  margin-left: 10vh;
}

.Myinfo p {
  width: 70%;
}

/* Over lay effect */

.overlay {
  position: absolute;
  bottom: 0;
  left: 100%;
  right: 0;
  background-color: white;
  overflow: hidden;
  width: 0;
  height: 100%;
  transition: 0.3s ease;
  border-radius: 12px;
}

.overlay-text {
  font-weight: 300;
  color: #2c4677;
  font-size: 17px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  white-space: nowrap;
}

button:hover .overlay {
  width: 100%;
  left: 0;
}

@media (max-width: 1200px) {
  .AboutMe h2 {
    /* font-size: 6rem; */
  }
}

/* main targer */

@media (max-width: 570px) {
  .Myinfo p {
    width: 100%;
  }
  .AboutMe h2 {
    font-size: 4rem;
  }
  .register {
    margin-left: 0;
    margin-top: 3vh;
  }
  .Myinfo {
    text-align: center;
    margin-left: 0;
  }
  .Myinfo p {
    font-size: 1.9rem;
  }
  .AboutMe {
    text-align: center;
  }
  .dev-button{
      font-size: 10px;
      font-weight: 500;
      width: 60%;
  }
  .dis-button{
      width: 30%;
  }
  .svgbtn{
    height: "12px";
    width: "12px";
  }
}

@media (max-width: 659px) {
  .register {
    margin-left: 0;
    margin-top: 3vh;
  }
  .AboutMe h1 {
    font-size: 2.2em;
  }
  .Myinfo p {
    font-size: 1.6rem;
  }
  .dev-button{
      font-size: 15px;
      font-weight: 500;
      width: 50%;
  }
  .dis-button{
      width: 20%;
  }
  .svgbtn{
    height: "12px";
    width: "12px";
  }
}

@media (max-width: 478px) {
  .register {
    margin-left: 0;
    margin-top: 3vh;
  }
  .AboutMe h1 {
    font-size: 2.2em;
  }
  .Myinfo p {
    font-size: 1.6rem;
  }
  .dev-button{
      font-size: 15px;
      font-weight: 500;
      width: 70%;
  }
  .dis-button{
      width: 25%;
      top: 10px;
  }
  .svgbtn{
    height: "12px";
    width: "12px";
  }
}

@media (max-width: 360px) {
  .register {
    margin-left: 0;
    margin-top: 3vh;
  }
  .AboutMe h1 {
    font-size: 2.2em;
  }
  .Myinfo p {
    font-size: 1.6rem;
  }
  .dev-button{
      font-size: 10px;
      font-weight: 500;
      width: 70%;
  }
  .dis-button{
      width: 25%;
  }
  .svgbtn{
    height: "12px";
    width: "12px";
  }
}
